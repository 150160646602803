




















// @ is an alias to /src

import { editPassword } from '@/api/users';
import { IInput } from '@/components/form';
import Input from '@/components/form/input.vue';
import Modal from '@/components/modal.vue';
import { IEventManager } from '@/models/event-manager';
import { IUser } from '@/models/user';
import router from '@/router';
import { IStateUser } from '@/store/modules/users';
import { IUsersTranslations } from '@/translations';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { eventActionPopup, eventsModal } from '../';

interface IUserManager extends IEventManager {
  id: number;
  user: IUser;
}

@Component({
  name: 'ResetPassword',
  components: { Modal, Input },
})
class ResetPassword extends Vue {
  @Getter('getUsers') public users!: IStateUser[];

  @Action('fetchUsers') public fetch!: () => void;

  @Getter('getSelectedUsers') public selected!: number[];

  @Getter('getUsersTranslations') public translations!: IUsersTranslations;

  public modal = eventsModal;
  public password = '';
  public eventActionPopup = eventActionPopup;
  public id = 0;
  public user!: IStateUser;
  public inputPassword = {} as IInput;

  public loading = false;

  public created() {
    this.inputPassword = {
      id: 'setup-user-password',
      label: 'New user password',
      placeholder: "Enter this user's new password",
      type: 'text',
      value: '',
    };

    if (this.selected.length === 1) {
      this.id = this.selected[0];
      if (this.users.length > 0) {
        this.findAndAssign();
      } else {
        this.fetch();
      }
    }
  }

  public findAndAssign() {
    const user = this.users.find(user => user.id === this.id);
    if (user) {
      this.user = user;
    }
  }
  public resetPassword() {
    if (this.user && this.password !== '') {
      this.loading = true;
      const user = this.users.find(user => user.id === this.selected[0]);
      if (user) {
        editPassword(this.password, user.email).then(() => {
          this.loading = false;
          router.push('/users');
        });
      }
    }
  }
  public close() {
    router.push('/events');
  }
}
export default ResetPassword;
